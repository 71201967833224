<template>
  <div
    id="user-details"
  >
    <!-- Overlay -->
    <b-overlay
      :show="submittingFetch"
      rounded
      opacity="0.6"
      spinner-medium
      spinner-variant="primary"
      spinner-type="grow"
    >
      <!-- Alert: No user found -->
      <profile-not-found
        :user="user"
        :submitting-fetch="submittingFetch"
      />
      <div v-if="user.id">
        <b-row class="match-height">
          <b-col>
            <b-card>
              <b-alert
                variant="primary"
                show
              >
                <div class="alert-heading h4 mb-25">
                  {{ $t('point.do_you_want_to_order_services_from_this_particular_contractor?') }}
                </div>
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="StarIcon"
                  />
                  <span class="align-middle">{{ $t('point.send_a_direct_request!') }}</span>
                </div>
              </b-alert>
              <!--              <b-alert-->
              <!--                variant="primary"-->
              <!--                show-->
              <!--              >-->
              <!--                <div class="alert-body">-->
              <!--                  <feather-icon-->
              <!--                    class="mr-25"-->
              <!--                    icon="InfoIcon"-->
              <!--                  />-->
              <!--                  <span class="align-middle">{{ $t('point.you_can_call_the_contractor_immediately_after_submitting_your_direct_request') }}</span>-->
              <!--                </div>-->
              <!--              </b-alert>-->

              <!--              <div class="mb-1">-->
              <!--                <b-badge-->
              <!--                  variant="light-warning"-->
              <!--                  class="position-absolute request-type pl-1"-->
              <!--                >-->
              <!--                  <small>-->
              <!--                    {{ $t('point.direct_request') }}-->
              <!--                  </small>-->
              <!--                  <feather-icon-->
              <!--                    icon="UserCheckIcon"-->
              <!--                  />-->
              <!--                </b-badge>-->
              <!--              </div>-->

              <request-form
                :user="user"
                :time-line="true"
                class="mt-1"
                @service-request-crated="(response)=>updateUserData(response)"
              />
            </b-card>
          </b-col>

        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BOverlay, BBadge, BAlert,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'

import ProfileNotFound from '@/views/pages/profile/ProfileNotFound.vue'
import RequestForm from '@/views/apps/service/request/edit/RequestEditWizard.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BOverlay,
    BBadge,
    BAlert,
    ProfileNotFound,
    RequestForm,

  },
  props: {
    userData: {
      type: Object,
      default: () => {},

    },
  },
  data() {
    return {

    }
  },

  methods: {

  },
  setup(props) {
    const user = ref({})
    const submittingFetch = ref(false)

    // Remote Data
    const fetchUser = () => {
      submittingFetch.value = true
      // Get point  id from URL
      const { route } = useRouter()
      const userId = route.value.params.id

      store.dispatch('user/fetchServiceProvider', { id: userId })
        .then(response => {
          user.value = store.getters['user/user']
          store.commit('user/setUser', user.value)
        })
        .catch(error => {
          if (error.response.status === 404) {
            user.value = undefined
          }
        }).finally(() => {
          submittingFetch.value = false
        })
    }

    const updateUserData = data => {
      if (data.receiver) {
        store.commit('user/updateUser', data.receiver)
      }
    }

    if (!props.userData) {
      fetchUser()
    } else user.value = props.userData

    return {
      user,
      submittingFetch,
      updateUserData,
    }
  },
}

</script>
<style scoped>
.badge{
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.request-type{
  top: 0;
  right:0;
  margin-right: 0!important;
  border-radius: 0 0 0 0.380rem!important;
}

</style>
